import styled from "react-emotion";

const VideoContent = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export default VideoContent;
