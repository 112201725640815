import styled from "react-emotion";

const DomainTitle = styled.h2`
    font-size: 1.125em;
    line-height: 1.556;
    font-weight: 600;
    letter-spacing: -0.013em;
    color: #000000;
    margin: 0 auto 1.3333em;
    text-align: center;

    @media screen and (min-width: 768px) {
        font-size: 2em;
        line-height: 1.5;
        margin-bottom: 1.5em;
        max-width: 75%;
    }
`;

export default DomainTitle;
