import styled from "react-emotion";

const VideoClose = styled.div`
    color: #ffffff;
    position: absolute;
    top: -32px;
    right: 0;
    z-index: 200;
    cursor: pointer;
`;

export default VideoClose;
