import styled from "react-emotion";

const VideoTriggerWrap = styled.figure`
    position: relative;
    cursor: pointer;
    max-width: 30em;
    margin: 0 auto;
    img {
        max-width: 100%;
    }
`;

export default VideoTriggerWrap;
