import styled from "react-emotion";

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1.5rem;

    @media screen and (min-width: 768px) {
        padding: 0 2rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        max-width: 58rem;
    }

    @media screen and (min-width: 1280px) {
        max-width: 70rem;
    }
`;

export default Container;
