import styled from "react-emotion";

const Circle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4em;
    height: 4em;
    border-radius: 50%;
    background-color: rgba(39, 154, 241, 0.5);;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    svg {
        position: relative;
        margin-left: 0.1875em;
        z-index: 3;
    }

    &::before {
        content: '';
        position: absolute;
        width: 78%;
        height: 78%;
        border-radius: 50%;
        background-color: #279AF1;
        flex-shrink: 0;
        z-index: 1;
        transition: all 0.3s;
    }
    &::after {
        content: '';
        position: absolute;
        width: 78%;
        height: 78%;
        border-radius: 50%;
        background-color: #279AF1;
        flex-shrink: 0;
        z-index: 1;
        transition: all 0.3s;
    }

    &:hover {
        &::before {
            width: 62.5%;
            height: 62.5%;
            transition: all 0.3s;
        }
        &::after {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all 0.3s;
        }
    }
`;

export default Circle;
