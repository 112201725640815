import styled from "react-emotion";

const Popup = styled.div`
    display: none;
    ${props =>
    props.isOpen &&
        "display: block;"
    };
`;

export default Popup;
