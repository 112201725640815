import React from "react";

import StyledIcon from "@common/components/StyledIcon";
import { Play } from "../assets/icons";
import Circle from "./Circle";

const PlayItem = () => {
    return (
        <Circle>
            <StyledIcon width="16" height="19">
                <Play/>
            </StyledIcon>
        </Circle>
    );
};

export default PlayItem;
