import React from "react";
import PropTypes from "prop-types";

import VideoOverlay from "./VideoOverlay";
import VideoWrapper from "./VideoWrapper";
import VideoContent from "./VideoContent";
import VideoRation from "./VideoRation";
import Popup from "./Popup";
import {Close} from "../assets/icons";
import VideoClose from "./VideoClose";
import { StyledIcon } from "@common/components";

const VideoPopup = ({ isOpen, popUpTrigger }) => {
    return (
        <Popup isOpen = {isOpen}>
            <VideoOverlay onClick = {popUpTrigger} />
            <VideoWrapper id="js_open-video--gide">
                <VideoClose onClick = {popUpTrigger}>
                    <StyledIcon>
                        <Close/>
                    </StyledIcon>
                </VideoClose>
                <VideoRation></VideoRation>
                <VideoContent src="https://www.youtube.com/embed/vRfbBfbYAwY" frameBorder="0" allowFullScreen=""></VideoContent>
            </VideoWrapper>
        </Popup>
    );
};

export default VideoPopup;

VideoPopup.propTypes = {
    isOpen: PropTypes.string.isRequired,
    popUpTrigger: PropTypes.string.isRequired
};
