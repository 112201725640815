import React from "react";
import PropTypes from "prop-types";

import PlayItem from "../PlayItem";
import VideoTriggerWrap from "./VideoTriggerWrap";

const VideoTrigger = ({popUpTrigger}) => {
    return(
        <VideoTriggerWrap onClick={popUpTrigger}>
            <PlayItem/>
            <img src="//i.ibb.co/7XxJqpQ/cover.jpg" alt="Зарегистрировать домен по лучшей цене на рынке" />
        </VideoTriggerWrap>
    );
};

export default VideoTrigger;

VideoTrigger.propTypes = {
    popUpTrigger: PropTypes.string.isRequired
};

