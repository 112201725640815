import styled from "react-emotion";

const VideoOverlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: #0b0b0b;
    opacity: .8;
    z-index: 100;
    /* display: none; */
`;

export default VideoOverlay;
