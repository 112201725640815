import styled from "react-emotion";

const VideoWrapper = styled.div`
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 102;
    /* display: none; */

    @media screen and (min-width: 1024px) {
        width: 45%;
    }
`;

export default VideoWrapper;
