import React from "react";
import styled from "react-emotion";

import Container from "./Container";
import VideoTrigger from "./VideoTrigger";
import DomainTitle from "./DomainTitle";
import VideoPopup from "./VideoPopup";

export default class VideoBlock extends React.Component {

    state = {
        open: false
    }

    popUpTrigger = () => {
        this.setState(({ open }) => {
            return{
                open: !open
            };
        });
    };

    render() {
        return (
            <Video>
                <Container>
                    <DomainTitle>Убедитесь, что зарегистрировать домен с&nbsp;помощью сервиса Nethouse.Домены очень&nbsp;просто</DomainTitle>
                    <VideoTrigger popUpTrigger = {this.popUpTrigger} />
                    <VideoPopup isOpen = {this.state.open} popUpTrigger = {this.popUpTrigger} />
                </Container>
            </Video>
        );
    }
}

const Video = styled.section`
    padding: 2em 0;

    @media screen and (min-width: 768px) {
        padding: 3em 0;
    }
    @media screen and (min-width: 1024px) {
        padding: 4em 0;
    }
`;
